'use client'

import React from 'react'
import { AuthModalTriggers } from '../user/auth-modals/auth-modal-triggers'
import { useUser } from '../user/user.store'
import styles from './page-header.module.css'
import clsx from 'clsx'

interface PageHeaderAuthTriggersProps {
  isAgRedesign?: boolean
}

export function PageHeaderAuthTriggers({
  isAgRedesign = false,
}: PageHeaderAuthTriggersProps) {
  const user = useUser()
  const pageHeaderLinkClassname = isAgRedesign
    ? styles.redesignedPageHeaderLink
    : styles.pageHeaderLink
  const Wrapper = isAgRedesign ? 'ul' : React.Fragment
  return !user?.id ? (
    <Wrapper>
      <AuthModalTriggers
        delay={0}
        wrapperLogInClassName={styles.pageHeaderLoginLink}
        wrapperSignUpClassName={styles.pageHeaderSignupLink}
        wrapperClassName={clsx(
          styles.pageHeaderAuthLinksWrapper,
          !isAgRedesign && styles.pageHeaderPipe
        )}
        logInLinkClassName={clsx(
          pageHeaderLinkClassname,
          isAgRedesign && styles.redesignedPageHeaderLoginLink
        )}
        signUpLinkClassName={pageHeaderLinkClassname}
        logInDataTid="nav-bar-log-in"
        signUpDataTid="nav-bar-sign-up"
        hideSignUp={isAgRedesign}
      />
    </Wrapper>
  ) : null
}
