'use client'

import { ReactComponent as HeartFilledIcon } from '@brand/icons/heart-filled.svg'
import { ReactComponent as HeartIcon } from '@brand/icons/heart.svg'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { userTotalSavedListings } from '../user/user.store'
import styles from './page-header.module.css'

export function PageHeaderSavedPageLink({
  mobileContent,
  isAgRedesign,
}: {
  mobileContent?: ReactNode
  isAgRedesign?: boolean
}) {
  const favoritesCount = useAtomValue(userTotalSavedListings)
  const hasFavorites = favoritesCount > 0
  const favoriteIcon = useMemo(
    () =>
      hasFavorites ? (
        <HeartFilledIcon
          className={styles.pageHeaderFavoriteFilledIcon}
          aria-hidden
        />
      ) : (
        <HeartIcon aria-hidden data-tid="empty-heart" />
      ),
    [hasFavorites]
  )

  return (
    <a
      className={clsx(
        styles.pageHeaderFavoriteLink,
        mobileContent && styles.pageHeaderHiddenWithMobileContent,
        isAgRedesign && styles.redesignedPageHeaderLink
      )}
      aria-label="Saved properties"
      href="/saved"
      data-tag_item="favorites_icon_header"
      data-tid="favorites-icon-btn"
    >
      {isAgRedesign ? (
        <span className={styles.pageHeaderSavedApts}>
          Saved Apts
          <span
            className={clsx(
              styles.pageHeaderCount,
              styles.redesignedPageHeaderCount
            )}
          >
            {hasFavorites && `(${favoritesCount})`}
          </span>
        </span>
      ) : (
        <>
          {favoriteIcon}
          {hasFavorites && (
            <span className={styles.pageHeaderCount}>{favoritesCount}</span>
          )}
        </>
      )}
    </a>
  )
}
