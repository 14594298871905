import { LeadWebsite } from '../../../__generated__/api-types'

export const brandName = 'ApartmentGuide.com'

export const brandSeoDisplayName = 'ApartmentGuide'

export const brandTrackingName = 'apartmentguide'

export const brandHostUrl = 'https://www.apartmentguide.com'

export const brandAppId = 'ag/www'

/**
 * This value is used for lead submissions.
 *
 * @param isMobile
 */
export function getBrandWebsite(isMobile: boolean) {
  return isMobile ? LeadWebsite.MApartmentguide : LeadWebsite.Apartmentguide
}
