'use client'

import { PageHeader as DefaultPageHeader } from '../../../../features/page-header/page-header'
import type { PageHeaderProps } from '../../../../features/page-header/page-header'
import { AgPageHeader } from './ag-page-header'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

export function PageHeader(props: PageHeaderProps) {
  const isAgRedesign =
    useFeatureVariable<number>(['ag_header_redesign', 'version'], 0) > 0
  return isAgRedesign ? (
    <AgPageHeader {...props} />
  ) : (
    <DefaultPageHeader {...props} />
  )
}
