import type { MouseEventHandler, ReactNode } from 'react'
import { brandName } from '@brand/config/brand-config'
import { Logo } from '@brand/slots/logo/logo'
import { NavLinks } from '@brand/slots/nav-links/nav-links'
import { ReactComponent as BackIcon } from '@brand/icons/back.svg'
import { ReactComponent as BackRedesignIcon } from '@brand/icons/back-redesign.svg'
import { SkipLink } from '../../../../components/skip-link/skip-link'
import type { PageHeader_QueryFragment } from '../../../../features/page-header/__generated__/page-header.gql'
import { DrawerMenu } from '@brand/slots/page-header/drawer-menu/drawer-menu'
import { PageHeaderAuthTriggers } from '../../../../features/page-header/page-header-auth-triggers'
import { PageHeaderUserMenu } from '../../../../features/page-header/page-header-user-menu'
import type { DrawerMenuLinks } from 'features/page-header/drawer-menu/drawer-menu.types'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import styles from './page-header.module.css'
import clsx from 'clsx'

export type PageHeaderOwnProps = {
  isFixed?: boolean
  /** Whether to show the back button on screens <= 1024px */
  showBackButton?: boolean
  mobileContent?: ReactNode
  marketTrendsUrl?: string | null
  seoLinks?: DrawerMenuLinks
  onBackClick?: MouseEventHandler<HTMLButtonElement>
}

export type PageHeaderProps = PageHeaderOwnProps & PageHeader_QueryFragment

const possibleSkipLinkTargetSelector = ['a', 'button', 'input', 'textarea']
  .map((str) => `main ${str}`)
  .join(',')

export function AgPageHeader(props: PageHeaderProps) {
  const isAgRedesign = useFeatureVariable<number>(
    ['ag_header_redesign', 'version'],
    0
  )

  const pageHeaderV1DetailPage = isAgRedesign === 1 && props.showBackButton
  const pageHeaderV1OtherPage = isAgRedesign === 1 && !props.showBackButton

  return (
    <header
      className={clsx(
        styles.pageHeader,
        isAgRedesign >= 2 && styles.pageHeaderV2,
        pageHeaderV1OtherPage && styles.pageHeaderV1OtherPage,
        pageHeaderV1DetailPage && styles.pageHeaderV1DetailPage,
        props.isFixed && styles.pageHeaderFixed
      )}
      data-tid="header"
      data-tag_section="header"
    >
      <div className={styles.pageHeaderLeft}>
        <SkipLink
          className={styles.pageHeaderSkipLink}
          selector={possibleSkipLinkTargetSelector}
          id=""
        />
        <div className={clsx(isAgRedesign && styles.pageHeaderButtons)}>
          {props.showBackButton && (
            <button
              className={styles.pageHeaderBack}
              aria-label="Back to search result"
              onClick={props.onBackClick}
              data-tag_item="back-button-arrow"
            >
              {isAgRedesign >= 2 ? (
                <BackRedesignIcon aria-hidden data-tid="back-button-arrow" />
              ) : (
                <BackIcon aria-hidden data-tid="back-button-arrow" />
              )}
            </button>
          )}

          <DrawerMenu
            location={props.location}
            marketTrendsUrl={props.marketTrendsUrl}
          />
        </div>
        <a
          href="/"
          data-tid="logo"
          className={styles.pageHeaderLogoLink}
          aria-label={brandName}
          data-tag_item="rent_logo"
        >
          <Logo className={styles.pageHeaderLogo} />
        </a>
      </div>
      {props.mobileContent && (
        <div className={styles.pageHeaderMobileContent}>
          {props.mobileContent}
        </div>
      )}

      <nav data-tid="header-nav">
        <ul
          className={clsx(styles.pageHeaderLinks, {
            [styles.pageHeaderLinksHiddenOnMobile]:
              props.mobileContent || props.showBackButton,
          })}
        >
          <NavLinks
            mobileContent={props.mobileContent}
            location={props.location}
            marketTrendsUrl={props?.marketTrendsUrl}
          />
        </ul>
      </nav>

      <div className={styles.placeholder}>
        {!props.mobileContent ? <PageHeaderAuthTriggers isAgRedesign /> : null}
        {!props.mobileContent ? <PageHeaderUserMenu isAgRedesign /> : null}
      </div>
    </header>
  )
}
