'use client'

import dynamic from 'next/dynamic'
import { useUser } from '../user/user.store'
import styles from './page-header.module.css'

const UserMenu = dynamic(() =>
  import('../user/user-menu/user-menu').then((mod) => mod.UserMenu)
)

export function PageHeaderUserMenu(props: { isAgRedesign?: boolean }) {
  const user = useUser()

  return user?.id && user.givenName ? (
    <UserMenu
      isAgRedesign={props.isAgRedesign}
      userFirstName={user.givenName}
      headerItemClassName={styles.pageHeaderLink}
    />
  ) : null
}
